const colors = {
  green: {
    "100": "#C6F6D5",
    "400": "#48BB78",
    "500": "#38A169",
    "600": "#25855A",
  },
  blue: {
    "50": "#eff4ff",
    "100": "#D6E9FF",
    "200": "#dae5ff",
    "300": "#bed2ff",
    "400": "#AEC9FF",
    "500": "#85ADFF",
    "600": "#5C92FF",
    "700": "#246CFF",
    "800": "#004EEB",
    "900": "#003BB3",
    "950": "#003297",
  },
  red: {
    "500": "#E53E3E",
    "100": "#FED7D7",
  },
  orange: {
    "50": "#fef5ee",
    "100": "#fde9d7",
    "200": "#facfae",
    "300": "#f7ad7a",
    "400": "#f38044",
    "500": "#ef5a19",
    "600": "#e14515",
    "700": "#ba3214",
    "800": "#942a18",
    "900": "#782516",
    "950": "#400f0a",
  },
  gray: {
    "50": "#F7FAFC", // <-
    "100": "#EDF2F7",
    "200": "#E2E8F0",
    "300": "#CBD5E0",
    "400": "#A0AEC0",
    "500": "#718096",
    "600": "#4A5568",
    "700": "#2D3748",
    "800": "#1A202C",
    "900": "#171923",
  },
  black: "#101112",
  white: "#ffffff",
  blackAlpha: {
    "50": "RGBA(16, 17, 18, 0.04)",
    "100": "RGBA(16, 17, 18, 0.06)",
    "200": "RGBA(16, 17, 18, 0.08)",
    "300": "RGBA(16, 17, 18, 0.16)",
    "400": "RGBA(16, 17, 18, 0.24)",
    "500": "RGBA(16, 17, 18, 0.36)",
    "600": "RGBA(16, 17, 18, 0.48)",
    "700": "RGBA(16, 17, 18, 0.64)",
    "800": "RGBA(16, 17, 18, 0.80)",
    "900": "RGBA(16, 17, 18, 0.92)",
  },
  github: "#171923",
  telegram: "#2775CA",
  linkedin: "#1564BA",
  discord: "#9747FF",
  slack: "#1BA27A",
  twitter: "#63B3ED",
  opensea: "#2081E2",
  facebook: "#4460A0",
  medium: "#231F20",
  reddit: "#FF4500",
};

export default colors;
